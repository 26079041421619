<template>
  <div>
    <!-- 库存详情查询 -->
    <filter-view :loading="loading" @submit="loadEchart">
      <stock-Filter :show-opt="showOpt" :query="query" @init="loadEchart" />
    </filter-view>
    <!-- 数据列表 -->
    <div class="result">
      <div class="padding-10" v-if="storeChart && storeChart.length > 0">
        <div>数据列表</div>
        <div v-for="(o, i) in storeChart" :key="i" class="text item padding-10-0">
          <div class="c h">
            <el-card class="box-card fs-small lh-150" shadow="never" style="width: 100%">
              <div>
                商品
                <span class="padding-0-05" />
                {{ o.goodsName || "--" }}
              </div>
              <div>
                编码
                <span class="padding-0-05" />
                {{ o.goodsCode || "--" }}
              </div>
              <div>
                区域/库存名称
                <span class="padding-0-05" />
                {{
                o.warehouseName || "--"
                }}
              </div>
              <div>
                数量
                <span class="padding-0-05" />
                {{ o.goodsCount || "--" }}
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import filterView from "../assembly/filter";
import stockFilter from "../assembly/filter/stock";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    stockFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      storeChart: [], //图表数据
      showRankNum: 3,
      chart: {
        gdBarChart: null,
        gdPieChart: null,
      },
      showOpt: {
        showRangGoods: true, //商品范围
        showCommodity: true, //指定商品
        showLevel: true, //汇总级别
        showWarehouseGroup: true,
      },
      query: {
        addressType: "area",
        treeNodeId: null,
        treeNodeType: null,
        StandardAreaGroup: null, //标准行政区域
        brandId: null,
        seriesId: null,
        categoryId: null,
        goods: null, //指定商品
        goodsGroup: "skuId", //汇总级别
        warehouseGroup: null,
        page: 0,
        size: 10000,
      },
    };
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      if (data.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          data.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          data.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          data.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          data.StandardAreaGroup = "district";
        }
        delete data.treeNodeType;
      } else {
        data.treeNodeType = this.query.treeNodeType;
        delete data.StandardAreaGroup;
      }
      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      delete data.dateRange;
      return data;
    },
    loadEchart() {
      let data = this.makeParams();
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/stock/count",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.storeChart = res.content;
        })
        .catch((err) => {
          this.storeChart = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>




